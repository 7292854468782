import { Typography } from "@material-ui/core";
import {
  getAuthContext,
  isBusinessDevelopment,
  isCustomerCare,
  isPartnerAdmin,
  isPartnerAdvancedAgent,
  isPartnerAgent,
  isPieAdmin,
  isSales,
  isUnderwriting,
  ErrorPage,
  isPremiumAudit
} from "@pie/components";
import { isEqual } from "lodash";
import queryString from "query-string";
import React, { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { goBack, push } from "connected-react-router";
import {
  AuthRoutes,
  PARTNER_CENTER_EMAIL,
  PieCrustSettings,
  DEFAULT_RETURN_TEXT
} from "../../constants";
import {
  analyticsAction,
  AnalyticsActionName,
  AnalyticsClickName,
  AnalyticsInternalProperty,
  AppName,
  selectAuthUser
} from "../../externals";
import {
  canShowPolicyRoute,
  isContractorCustomerCare,
  publicRedirectRoute,
  publicRoutes
} from "../../routes/routes";
import { authUserActions } from "../../store/authUser";
import { store } from "../../store/createStore";
import { Home } from "../Home/Home";
import { Layout } from "../Layout";
import { NotFound } from "../NotFound/NotFound";
import "./App.css";
import { GuardRoute } from "./GuardRoute";
import { useSettings } from "@pie/components";
import { PolicySubmissionRedirect } from "../PolicySubmissionRedirect/PolicySubmissionRedirect";

import { useAppStyles } from "./styles";
import { SignOut } from "../../pages/SignOut/SignOut";
import { MicroUIWrapper } from "./MicroUIWrapper/MicroUIWrapper";
import { clearAllCookies } from "../../utils/cookieUtils";
import { NewAppRoute } from "./NewAppRoute";

const App: React.FC = () => {
  const { search, pathname } = useLocation();
  const { isAuthenticated, user, loading } = useContext(getAuthContext());
  const settings = useSettings<PieCrustSettings>();
  const enablePolicy = settings?.features?.Policy;
  const reduxAuthUser = useSelector(selectAuthUser);

  const dispatch = useDispatch();
  const initialQueryParams = useRef<string>(search);

  useEffect(() => {
    if (user && !isEqual(reduxAuthUser, user)) {
      if (settings.useCustomAuth) {
        clearAllCookies();
      }
      // shallow JS compare fails
      dispatch(authUserActions.setAuthUser(user));
    }

    // if we have a subject (used as the analytics distinctId), send the analytics event to link their landing queryparams to their session
    if (user?.subject && initialQueryParams.current) {
      // queryString.parse is initalized with Object.create(null) and lacks a prototype. This spread prevents a redux serializable data error
      const properties = {
        ...queryString.parse(initialQueryParams.current),
        [AnalyticsInternalProperty.SOURCE]: AppName
      };
      dispatch(
        analyticsAction({
          actionName: AnalyticsActionName.LAND_WITH_QUERYPARAMS,
          properties
        })
      );
      initialQueryParams.current = "";
    } else if (user === null) dispatch(authUserActions.clearAuthUser());
  }, [dispatch, user, reduxAuthUser, isAuthenticated, settings.useCustomAuth]);
  const { root } = useAppStyles();
  const handleBackClick = (): void => {
    dispatch(
      analyticsAction({
        actionName: AnalyticsClickName.TECH_ERROR_PAGE,
        type: "Backclick",
        options: ["trackRoute"],
        properties: {
          [AnalyticsInternalProperty.SOURCE]: AppName
        }
      })
    );
    dispatch(goBack());
  };
  const handleButtonClick = (): void => {
    dispatch(
      analyticsAction({
        actionName: AnalyticsClickName.TECH_ERROR_PAGE_RETURN,
        type: "Click",
        options: ["trackRoute"],
        properties: {
          [AnalyticsInternalProperty.SOURCE]: AppName
        }
      })
    );
    dispatch(push(AuthRoutes.DASHBOARD));
  };
  return (
    <Typography component="div" className={root}>
      <div id="personalizationMessageContainer" />
      {isAuthenticated ? (
        <Layout pathname={pathname}>
          <Switch>
            {/* Error Page */}
            <Route
              key={AuthRoutes.ERROR}
              path={AuthRoutes.ERROR}
              render={() => (
                <ErrorPage
                  returnText={DEFAULT_RETURN_TEXT}
                  appId="00"
                  contactEmail={PARTNER_CENTER_EMAIL}
                  handleBackClick={handleBackClick}
                  handleReturnClick={handleButtonClick}
                />
              )}
            />
            {/* Appetite Checker */}
            <Route
              key={AuthRoutes.APPETITE_CHECKER}
              path={AuthRoutes.APPETITE_CHECKER}
              render={() => (
                <MicroUIWrapper
                  route={AuthRoutes.APPETITE_CHECKER}
                  appName="appetitechecker"
                  store={store}
                />
              )}
            />
            {/* Policy */}
            {enablePolicy && (
              <GuardRoute
                allowRoute={[canShowPolicyRoute]}
                key={AuthRoutes.POLICY}
                path={AuthRoutes.POLICY}
                render={() => (
                  <MicroUIWrapper
                    route={AuthRoutes.POLICY}
                    appName="policyui"
                    store={store}
                  />
                )}
              />
            )}
            {/* SalesForce Redirect*/}
            <GuardRoute
              allowRoute={[
                isSales,
                isUnderwriting,
                isCustomerCare,
                isPremiumAudit,
                isContractorCustomerCare
              ]}
              key={AuthRoutes.SUBMISSION_REDIRECT}
              path={AuthRoutes.SUBMISSION_REDIRECT}
              render={() => (
                <PolicySubmissionRedirect user={user} search={search} />
              )}
            />
            {/* BindUI */}
            <GuardRoute
              allowRoute={[
                isUnderwriting,
                isSales,
                isPartnerAdmin,
                isPartnerAgent,
                isPartnerAdvancedAgent
              ]}
              key={AuthRoutes.BIND}
              path={AuthRoutes.BIND}
              render={() => (
                <MicroUIWrapper
                  route={AuthRoutes.BIND}
                  appName="bindui"
                  store={store}
                />
              )}
            />
            {/* Renewals */}
            <GuardRoute
              allowRoute={[isUnderwriting]}
              key={AuthRoutes.RENEWALS}
              path={AuthRoutes.RENEWALS}
              render={() => (
                <MicroUIWrapper
                  route={AuthRoutes.RENEWALS}
                  appName="renewals"
                  store={store}
                />
              )}
            />
            {/* UW-Rater */}
            <GuardRoute
              allowRoute={[isUnderwriting]}
              key={AuthRoutes.UW_RATER}
              path={AuthRoutes.UW_RATER}
              render={() => <NewAppRoute path="/uw-rater" />}
            />
            {/* Dashboard */}
            <GuardRoute
              allowRoute={[
                isSales,
                isPartnerAgent,
                isPartnerAdmin,
                isPartnerAdvancedAgent,
                isBusinessDevelopment,
                isContractorCustomerCare
              ]}
              key={AuthRoutes.DASHBOARD}
              path={AuthRoutes.DASHBOARD}
              render={(props): JSX.Element => (
                <MicroUIWrapper
                  route={AuthRoutes.DASHBOARD}
                  appName="partnerportaldashboard"
                  store={store}
                />
              )}
            />
            {/* Agency Flow */}
            <GuardRoute
              allowRoute={[
                isSales,
                isPartnerAgent,
                isPartnerAdmin,
                isPartnerAdvancedAgent,
                isUnderwriting,
                isBusinessDevelopment
              ]}
              key={AuthRoutes.AGENCY_FLOW}
              path={AuthRoutes.AGENCY_FLOW}
              render={(): JSX.Element => (
                <MicroUIWrapper
                  route={AuthRoutes.AGENCY_FLOW}
                  appName="razzleberry"
                  store={store}
                />
              )}
            />
            {/* Agency Admin */}
            <GuardRoute
              allowRoute={[
                isBusinessDevelopment,
                isCustomerCare,
                isContractorCustomerCare
              ]}
              key={AuthRoutes.AGENCY_ADMIN}
              path={AuthRoutes.AGENCY_ADMIN}
              render={(): JSX.Element => (
                <MicroUIWrapper
                  route={AuthRoutes.AGENCY_ADMIN}
                  appName="agencyadmintool"
                  store={store}
                />
              )}
            />
            {/* User Management */}
            <GuardRoute
              allowRoute={[isPartnerAdmin]}
              key={AuthRoutes.USER_MANAGEMENT}
              path={AuthRoutes.USER_MANAGEMENT}
              render={(): JSX.Element => (
                <MicroUIWrapper
                  route={AuthRoutes.USER_MANAGEMENT}
                  appName="usermanagement"
                  store={store}
                />
              )}
            />
            {/* Billing */}
            <GuardRoute
              allowRoute={[isPieAdmin]}
              key={AuthRoutes.BILLING}
              path={AuthRoutes.BILLING}
              render={() => (
                <MicroUIWrapper
                  route={AuthRoutes.BILLING}
                  appName="billingui"
                  store={store}
                />
              )}
            />
            {/* Api Key Management */}
            <GuardRoute
              allowRoute={[isPartnerAdmin]}
              key={AuthRoutes.API_KEYS}
              path={AuthRoutes.API_KEYS}
              render={(props): JSX.Element => (
                <MicroUIWrapper
                  route={AuthRoutes.API_KEYS}
                  appName="apikeymanagement"
                  store={store}
                />
              )}
            />
            <Route key={"/sign-out"} path={"/sign-out"} component={SignOut} />
            <Route component={Home} path="/sign-in" />
            <Redirect path={"/uw-legacy"} to={`/uw-rater${search}`} />
            {/* All the app routes that should be redirected to next */}
            <NewAppRoute path="/rater" />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      ) : (
        <Switch>
          {publicRoutes}
          {!loading && publicRedirectRoute}
        </Switch>
      )}
    </Typography>
  );
};

export default App;
