import React from "react";
import { Grid, Typography, Button, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useSettings,
  UserRole,
  isSales,
  isBusinessDevelopment,
  isUnderwriting,
  notificationService
} from "@pie/components";

import { useSidebarStyles } from "./styles";
import { filterNavOptions } from "./navOptions";
import {
  analyticsAction,
  AnalyticsClickName,
  AnalyticsInternalProperty,
  AppName,
  selectAuthUser
} from "../../externals";

import { SidebarNavOption } from "./SidebarNavOption/SidebarNavOption";
import {
  AGENCY_SERVICE_PHONE,
  AGENCY_SERVICE_EMAIL,
  COMM_AUTO_RESOURCES,
  PARTNER_CENTER_EMAIL,
  PieCrustSettings,
  TestIds,
  WORKERS_COMP_RESOURCES
} from "../../constants";

import {
  agencyAdminOptions,
  allUsersNavOptions,
  billingNavOptions,
  byUserNavOptions,
  determineRoleHome,
  policyUIOptions,
  razzleberryBaseRoute,
  userManagementOptions,
  apiKeyUiOptions
} from "../../routes/routes";
import { selectCanUseApiKeys } from "../../externals/store/agencyRules";
import { useMount } from "@pie/utils";
import { agencyRulesActions } from "../../store/agencyRules/agencyRules";
import { Icon as IconAtom, LogoOrangeNameRight } from "@pie/icon-atom";

export interface SidebarProps {
  id?: string;
}

export const Sidebar: React.FC<SidebarProps> = ({ id }) => {
  const settings = useSettings<PieCrustSettings>();

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(agencyRulesActions.getAgencyRules());
  });
  const authUser = useSelector(selectAuthUser);
  const shouldAddApiKeyOptions = useSelector(selectCanUseApiKeys);
  const { role } = authUser;
  const {
    buttonMargin,
    sideBar,
    email,
    logo: logoClass,
    upperWrapper
  } = useSidebarStyles();

  const isPolicyEnabled = Boolean(settings.features?.Policy);
  const navOptions = [
    ...(isPolicyEnabled ? policyUIOptions : []),
    ...agencyAdminOptions,
    ...byUserNavOptions,
    ...allUsersNavOptions,
    ...userManagementOptions,
    ...(shouldAddApiKeyOptions ? apiKeyUiOptions : []),
    ...(settings.features?.Billing ? billingNavOptions : [])
  ];

  const handleLogoClick = (): void => {
    dispatch(
      analyticsAction({
        actionName: AnalyticsClickName.PIE_LOGO,
        type: "Click",
        options: ["trackRoute"],
        properties: {
          [AnalyticsInternalProperty.SOURCE]: AppName
        }
      })
    );
  };

  const filteredNavOptions = [...filterNavOptions(authUser, navOptions)];

  const shouldShowQuoteButton =
    role !== UserRole.PieAdmin ||
    isSales(authUser) ||
    isBusinessDevelopment(authUser) ||
    isUnderwriting(authUser);

  return (
    <div data-testid={TestIds.SIDEBAR} id={id || "sidebar"}>
      <Grid item classes={{ root: sideBar }}>
        <Grid item classes={{ root: upperWrapper }}>
          <Grid className={logoClass} item>
            <RouterLink
              data-testid={TestIds.ROUTED_LOGO}
              to={determineRoleHome(authUser, isPolicyEnabled)}
              onClick={handleLogoClick}
            >
              <IconAtom source={LogoOrangeNameRight} />
            </RouterLink>
          </Grid>
          {shouldShowQuoteButton && (
            <Button
              data-testid={TestIds.QUOTE_BUTTON}
              to={`${razzleberryBaseRoute}/start`}
              variant="outlined"
              color="primary"
              classes={{ root: buttonMargin }}
              component={RouterLink}
            >
              Get a Quote
            </Button>
          )}
          {filteredNavOptions.map(o => (
            <SidebarNavOption key={o.name} {...o} />
          ))}
        </Grid>
        {role !== UserRole.PieAdmin && (
          <Grid
            container
            className={email}
            data-testId={TestIds.NAV_CONTACT}
            direction="column"
          >
            <Typography variant="body1" gutterBottom>
              Resources
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link
                href={WORKERS_COMP_RESOURCES}
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
              >
                Workers' Comp Resources
              </Link>
            </Typography>
            <Typography
              style={{ marginBottom: "20px" }}
              variant="body2"
              gutterBottom
            >
              <Link
                href={COMM_AUTO_RESOURCES}
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
              >
                Commercial Auto Resources
              </Link>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              onClick={() => {
                notificationService.addNotification({
                  notificationType: "error",
                  message: "Hello"
                });
              }}
            >
              Contact Pie
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Submissions and Quotes
            </Typography>
            <Typography variant="caption" paragraph>
              <Link href={`mailto:${PARTNER_CENTER_EMAIL}`} underline="always">
                {PARTNER_CENTER_EMAIL}
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Bound Policies
            </Typography>
            <Typography variant="caption" gutterBottom>
              <Link href={`tel:${AGENCY_SERVICE_PHONE}`} underline="always">
                {AGENCY_SERVICE_PHONE}
              </Link>
            </Typography>
            <Typography variant="caption" gutterBottom>
              <Link href={`mailto:${AGENCY_SERVICE_EMAIL}`} underline="always">
                {AGENCY_SERVICE_EMAIL}
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
