export const SUBS_EMAIL = "subs@pieinsurance.com";

export const AGENCY_SERVICE_EMAIL = "agencyservice@pieinsurance.com";

export const PARTNER_CENTER_EMAIL = "subs@pieinsurance.com";

export const AGENCY_SERVICE_PHONE = "855-275-9884";

export const WORKERS_COMP_RESOURCES =
  "https://www.pieinsurance.com/agency/partner-resources";
export const COMM_AUTO_RESOURCES =
  "https://www.pieinsurance.com/agency/partner-resources#comm-auto";
