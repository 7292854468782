import {
  Assignment,
  Payment as PaymentIcon,
  PersonAdd
} from "@material-ui/icons";
import {
  AuthUser,
  checkRolesConditionally,
  isBusinessDevelopment,
  isClaims,
  isCustomerCare,
  isPieAdmin,
  isPieGroup,
  isPremiumAudit,
  isSales,
  isUnderwriting,
  UserGroup,
  UserRole
} from "@pie/components";
import React from "react";
import { Redirect, Route } from "react-router";
import { AuthRoutes, PublicRoutes, TestIds } from "../constants";
import { FilterableNavOption } from "../externals";
import {
  ApiKeyIcon,
  AppetiteCheckerIcon,
  DashboardIcon,
  UserManagementIcon
} from "../icons";
import { SignIn } from "../pages/SignIn";
import { SignOut } from "../pages/SignOut/SignOut";

export const isContractorCustomerCare = (authUser: AuthUser): boolean =>
  isPieGroup(authUser, "!pie.contractor.dm" as UserGroup);

export const allUsersNavOptions: FilterableNavOption[] = [
  {
    name: "appetite-checker",
    label: "Appetite Checker",
    path: AuthRoutes.APPETITE_CHECKER,
    IconComponent: AppetiteCheckerIcon,
    roles: [
      UserRole.PartnerAgent,
      UserRole.PartnerAdmin,
      UserRole.PartnerAdvancedAgent,
      UserRole.PieAdmin
    ],
    predicates: [
      isBusinessDevelopment,
      isCustomerCare,
      isContractorCustomerCare,
      isSales,
      isUnderwriting
    ],
    testId: TestIds.NAV_APPETITE
  }
];

export const billingNavOptions: FilterableNavOption[] = [
  {
    name: "billing",
    label: "Billing",
    path: AuthRoutes.BILLING,
    IconComponent: PaymentIcon,
    roles: [UserRole.PieAdmin],
    predicates: [
      isSales,
      isCustomerCare,
      isBusinessDevelopment,
      isUnderwriting
    ],
    testId: TestIds.NAV_BILLING
  }
];

export const userManagementOptions: FilterableNavOption[] = [
  {
    name: "userManagement",
    label: "User Management",
    path: AuthRoutes.USER_MANAGEMENT,
    IconComponent: UserManagementIcon,
    roles: [UserRole.PartnerAdmin],
    predicates: [],
    testId: TestIds.NAV_USER_MANAGEMENT
  }
];

export const razzleberryBaseRoute = "/agency-flow";

export const agencyAdminOptions: FilterableNavOption[] = [
  {
    name: "provisioning",
    label: "Provisioning",
    path: `${AuthRoutes.AGENCY_ADMIN}/provisioning`,
    IconComponent: PersonAdd,
    roles: [UserRole.PieAdmin],
    predicates: [
      isBusinessDevelopment,
      isCustomerCare,
      isContractorCustomerCare
    ],
    testId: TestIds.NAV_PROVISIONING
  },
  {
    name: "roster",
    label: "Agency Roster",
    path: `${AuthRoutes.AGENCY_ADMIN}/roster`,
    IconComponent: Assignment,
    roles: [UserRole.PieAdmin],
    predicates: [
      isBusinessDevelopment,
      isCustomerCare,
      isContractorCustomerCare
    ],
    testId: TestIds.NAV_ROSTER
  }
];

export const byUserNavOptions: FilterableNavOption[] = [
  {
    name: "dashboard",
    label: "Dashboard",
    path: AuthRoutes.DASHBOARD,
    IconComponent: DashboardIcon,
    roles: [
      UserRole.PartnerAgent,
      UserRole.PartnerAdmin,
      UserRole.PartnerAdvancedAgent,
      UserRole.PieAdmin
    ],
    predicates: [isSales],
    testId: TestIds.NAV_DASHBOARD
  }
];

export const policyUIOptions = [
  {
    name: "dashboard",
    label: "Dashboard",
    path: AuthRoutes.POLICY_SEARCH,
    IconComponent: DashboardIcon,
    roles: [UserRole.PieAdmin],
    predicates: [
      isCustomerCare,
      isUnderwriting,
      isClaims,
      isPremiumAudit,
      isBusinessDevelopment,
      isContractorCustomerCare
    ],
    testId: TestIds.NAV_DASHBOARD
  }
];

export const apiKeyUiOptions = [
  {
    name: "api-keys",
    label: "API Keys",
    path: `${AuthRoutes.API_KEYS}`,
    IconComponent: ApiKeyIcon,
    roles: [UserRole.PartnerAdmin],
    predicates: [],
    testId: TestIds.NAV_API_KEYS
  }
];

export const publicRoutes = [
  <Route
    key={PublicRoutes.SIGN_IN}
    path={PublicRoutes.SIGN_IN}
    component={SignIn}
  />,
  <Route
    key={PublicRoutes.SIGN_OUT}
    path={PublicRoutes.SIGN_OUT}
    component={SignOut}
  />
];

export const publicRedirectRoute = [
  <Route
    key={PublicRoutes.SIGN_IN}
    component={(): JSX.Element => <Redirect to={PublicRoutes.SIGN_IN} />}
  />
];

export const canShowPolicyRoute = (authUser: AuthUser): boolean =>
  checkRolesConditionally({
    and: [isPieAdmin],
    or: [
      isCustomerCare,
      isUnderwriting,
      isClaims,
      isPremiumAudit,
      isBusinessDevelopment,
      isContractorCustomerCare
    ]
  })(authUser);

export const determineRoleHome = (
  authUser: AuthUser,
  isPolicyEnabled: boolean
): AuthRoutes => {
  switch (authUser.role) {
    case UserRole.PartnerAdmin:
    case UserRole.PartnerAgent:
    case UserRole.PartnerAdvancedAgent:
      return AuthRoutes.DASHBOARD;
    case UserRole.PieAdmin:
      if (isSales(authUser)) {
        return AuthRoutes.DASHBOARD;
      } else if (isPolicyEnabled && canShowPolicyRoute(authUser)) {
        return AuthRoutes.POLICY_SEARCH;
      } else {
        return AuthRoutes.AGENCY_ADMIN;
      }
    // fall through
    default:
      return AuthRoutes.DASHBOARD;
  }
};
