export enum PublicRoutes {
  SIGN_IN = "/sign-in",
  SIGN_OUT = "/sign-out"
}

export enum AuthRoutes {
  HOME = "/",
  ERROR = "/error",
  AGENCY_FLOW = "/agency-flow",
  AGENCY_ADMIN = "/agency-admin",
  DASHBOARD = "/dashboard",
  USER_MANAGEMENT = "/user-management",
  APPETITE_CHECKER = "/appetite-checker",
  UW_RATER = "/uw-rater",
  RENEWALS = "/renewals",
  BIND = "/bind",
  POLICY = "/policy",
  POLICY_SEARCH = "/policy/search",
  BIND_SUMMARY = "/bind/confirmation",
  SUBMISSION_REDIRECT = "/redirect",
  BILLING = "/billing",
  API_KEYS = "/api-keys"
}
